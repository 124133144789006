/* eslint-disable import/namespace */
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

import { Sentry } from "~/services/sentry";

const { PROD: IS_PROD, VITE_DEPLOYMENT_ENVIRONMENT } = import.meta.env;

Sentry.init({
  enabled: IS_PROD,
  environment: VITE_DEPLOYMENT_ENVIRONMENT,
  dsn: "https://e3725164a3b01fbc0232f8bcc80986b9@o4507935725780992.ingest.us.sentry.io/4507940548706304",

  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.feedbackIntegration({
      colorScheme: "system",
      themeLight: {
        accentBackground: "#000",
      },
    }),
  ],

  tracesSampler: ({ name }) => {
    if (name.includes("healthcheck")) {
      return 0;
    }

    return 0.5;
  },
  profilesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
